import React from 'react';
import { Container } from 'reactstrap';
import { Outlet } from 'react-router-dom';
import NavMenu from './NavMenu';
import { Auth } from '../authentication/useAuth';

export default function Layout() {

  return (
    <>
      <Auth>
        <NavMenu />
        <Container tag="main">
          <Outlet />
        </Container>
      </Auth>
    </>
  );
}
