import React from 'react';
import { DayObject } from './Calendar';
import { Prediction } from '../Planner';
import { Chip } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const theme = createTheme({
  palette: {
    primary: { main: green[500] },
    secondary: { main: grey[900] }
  }
});

export function CalendarDayBody({ day, predictions, click }: {
  day: DayObject,
  predictions: Prediction[],
  click: (day: DayObject, prediction: Prediction) => void
}) {
  return (
    <ThemeProvider theme={theme}>
      <div className="day-grid-item-body">
        {
          predictions?.map((p, i) => (
            <Chip key={i} sx={{ zIndex: 1000 }}
              icon={p.parentId && <InsertLinkIcon />}
              label={p.name} color={p.incoming ? "primary" : "secondary"} onClick={() => { click(day, p) }} />
          ))}
      </div>
    </ThemeProvider>
  );
}
