import React from 'react';
import { useRoutes } from 'react-router-dom';
import AppRoutes from './AppRoutes';

import './custom.css';

export default function App() {

  let routes = useRoutes(AppRoutes);

  return (
    <>
      {routes}
    </>
  );
}