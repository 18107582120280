import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../custom.css";
import { DataGrid, GridActionsCellItem, GridCellParams, GridColDef, GridEventListener, GridRenderEditCellParams, GridRowEditStopReasons, GridRowId, GridRowModes, GridRowModesModel, GridRowParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarQuickFilter, useGridApiContext } from '@mui/x-data-grid';
import { User } from "../../authentication/User";
import { Box, Button, Checkbox, ListItemText, MenuItem, Select } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import PasswordIcon from '@mui/icons-material/Password';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import UserPasswordModal from "./UserPasswordModal";
import { useAuth } from "../../authentication/useAuth";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export default function Users() {

    const { user: currentUser, impersonate } = useAuth();
    const navigate = useNavigate();

    const useUpdateMutation = () => {
        return React.useCallback(
            (user: Partial<User>) =>
                new Promise<Response>(async (resolve, reject) => {
                    var userRequest = await UserService.updateUser(user as User);
                    if (!userRequest.ok) {
                        reject(new Error('Error while saving user'));
                    } else {
                        resolve(userRequest);
                    }
                }),
            [],
        );
    }

    const UserService = useMemo(() => ({
        getUsers: async () => {
            const userRequest = await fetch("/api/user/all", { method: "GET", credentials: "include" });
            const allUsers = (await userRequest.json()) as User[];
            return allUsers;
        },

        updateUser: async (user: User) => {
            const userRequest = await fetch("/api/user", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify(user)
            });
            return userRequest;
        },

        deleteUser: async (id: string) => {
            const userRequest = await fetch(`/api/user/${id}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                method: "DELETE",
                credentials: "include"
            });
            return userRequest;
        },

        impersonate: async (id: string) => {
            await impersonate(id).then(() => {
                navigate("/");
            });
        }
    }), []);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => async () => {
        const success = await UserService.deleteUser(id as string);
        if (success.ok) {
            setUsers(users.filter((u) => u.id !== id));
            setSnackbar({ children: 'User deleted!', severity: 'success' });
        }
        else {
            setSnackbar({ children: 'User cannot be deleted right now...', severity: "error" });
        }
    };

    const [passwordModalState, setPasswordModalState] = useState({ open: false, userId: "", email: "" });

    const handleSetPasswordClick = (id: GridRowId, row: User) => async () => {
        setPasswordModalState({ open: true, userId: id as string, email: row.email });
    };

    const handleImpersonateUserClick = (id: GridRowId, row: User) => async () => {
        await UserService.impersonate(id as string);
    };

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = users.find((user) => user.id === id);
        if (editedRow!.isNew) {
            setUsers(users.filter((u) => u.id !== id));
        }
    };

    var mutate = useUpdateMutation();

    const processRowUpdate = async (newUser: User) => {
        await mutate(newUser);

        const updatedRow = { ...newUser, isNew: false };
        setUsers(users.map((u) => (u.id === newUser.id ? updatedRow : u)));
        setSnackbar({ children: 'User successfully saved', severity: 'success' });
        return updatedRow;
    };

    const processPasswordChange = async (id: string, password: string) => {
        const user = users.find(u => u.id === id);
        await mutate({ ...user, password: password });
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const [snackbar, setSnackbar] = React.useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);

    const handleCloseSnackbar = () => setSnackbar(null);
    function MultiSelectEditComponent(props: GridRenderEditCellParams) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value; // The new value entered by the user
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        return <Select
            sx={{ fontSize: '0.875rem' }}
            size='small'
            variant="standard"
            multiple
            value={value}
            onChange={handleValueChange}
            renderValue={(value: string[]) => `${value.join(", ")}`}>
            {["Founder", "Admin", "Standard"].map((name) => (
                <MenuItem key={name} value={name} disabled={name === "Founder"}>
                    <Checkbox checked={value.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                </MenuItem>
            ))}
        </Select>
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'name', headerName: 'Name', width: 130, editable: true },
        { field: 'email', headerName: 'Email', width: 250, editable: true },
        { field: 'enabled', headerName: 'Enabled', width: 100, editable: true, type: 'boolean' },
        {
            field: 'roles',
            headerName: 'Roles',
            description: 'User roles',
            sortable: false,
            width: 350,
            flex: 1,
            editable: true,
            valueFormatter: (value: string[]) => `${value.join(", ")}`,
            renderEditCell: (params: GridRenderEditCellParams) => (
                <MultiSelectEditComponent {...params} />
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 130,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                        disabled={(row.roles as string[]).includes("Founder") || row.id === currentUser.id}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<PasswordIcon />}
                        label="Set Password"
                        onClick={handleSetPasswordClick(id, row)}
                        color="inherit"
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<SupervisorAccountIcon />}
                        label="Impersonate"
                        onClick={handleImpersonateUserClick(id, row)}
                        color="inherit"
                        showInMenu
                    />
                ];
            }
        }
    ];

    const [users, setUsers] = useState<User[]>();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const getUsers = useCallback(() => UserService.getUsers().then((u) => setUsers(u)), [UserService]);
    useEffect(() => { getUsers(); }, [getUsers]);

    const CustomToolbar = () => {
        const handleAddClick = () => {
            const id = crypto.randomUUID();
            setUsers((users) => [...users, {
                id: id,
                name: "",
                email: "",
                roles: ["Standard"],
                enabled: true,
                isNew: true,
                password: "",
                onBehalfOf: null,
                created: { by: currentUser, on: new dayjs.Dayjs() },
                modified: { by: currentUser, on: new dayjs.Dayjs() }
            }]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
            }));
        };

        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter />
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector
                    slotProps={{ tooltip: { title: 'Change density' } }}
                />
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="contained" size="small" onClick={handleAddClick}>
                    <AddIcon />Add
                </Button>
                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <DataGrid
                sx={{ minWidth: '0' }}
                rows={users}
                columns={columns}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                isRowSelectable={(params: GridRowParams) => {
                    return !(params.row.roles as string[]).includes("Founder");
                }}
                isCellEditable={(params: GridCellParams) => {
                    if (params.field === "enabled" && params.row.id === currentUser.id)
                        return false;
                    return true;
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                slotProps={{
                    toolbar: { setUsers, setRowModesModel },
                }}
                editMode="row"
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={4000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
            <UserPasswordModal
                show={passwordModalState.open}
                email={passwordModalState.email}
                handlePasswordChange={(password: string) => {
                    return processPasswordChange(passwordModalState.userId, password);
                }}
                handleClose={(result) => {
                    setPasswordModalState({ ...passwordModalState, open: false, userId: "" });
                    if (result.isSuccess) {
                        setSnackbar({ children: "Password changed!", severity: "success" });
                    } else if (!result.isSuccess && !result.hasCancelled) {
                        setSnackbar({ children: "Password change has failed.", severity: "error" });
                    }
                }} />
        </div>
    );
}