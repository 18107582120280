import React, { useMemo, useState } from 'react';
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/useAuth';
import './NavMenu.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function NavMenu() {

  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { user, logout } = useAuth();

  async function handleLogout() {
    logout();
    navigate("/");
  }

  const userName = useMemo(() => {
    let name = user?.name;
    if (user?.onBehalfOf && user.onBehalfOf?.name) { 
      name = `${name} (${user.onBehalfOf.name})`
    }
    return name;
  }, [user]);

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
        <NavbarBrand tag={Link} to="/">my_new_app</NavbarBrand>
        <NavbarToggler onClick={() => setCollapsed(state => !state)} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
            </NavItem>
            {user && user?.roles?.includes("Standard") &&
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/planner">Planner</NavLink>
              </NavItem>}
            {!user &&
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>
              </NavItem>}
            {user &&
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <AccountCircleIcon sx={{ margin: "-2px 4px 0px 0px" }}></AccountCircleIcon>
                  {userName}
                </DropdownToggle>
                <DropdownMenu className="position-absolute end-0">
                  {["Founder", "Admin"].some(r => user?.roles?.includes(r)) &&
                    <DropdownItem tag={Link} to="/administration">Administration</DropdownItem>}
                  <DropdownItem tag={Link} to="/preferences">Preferences</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>}
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
}
