import React, { useEffect, useState } from 'react';
import { DayObject } from './Calendar';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DoneIcon from '@mui/icons-material/Done';
import "./styles.css";

export function CalendarDayTotal({ day, total, click }: {
  day: DayObject,
  total: [number, boolean],
  click: (day: DayObject, amount?: number) => void;
}) {

  const [calculatedTotal, isCalculated] = total;
  const [mode, setMode] = useState(false);

  const totalAmount = calculatedTotal !== 0 ? Intl.NumberFormat("en-GB",
    {
      style: 'currency',
      currency: 'GBP'
    }).format(calculatedTotal) : null;

  const [amount, setAmount] = useState<string | undefined>(calculatedTotal.toFixed(2));
  useEffect(() => { setAmount(calculatedTotal.toFixed(2)) }, [calculatedTotal]);

  function handleClick() {
    setMode(() => !mode);
    if (mode) {
      click(day, amount.length > 0 ? Number(amount) : null);
    }
  }

  return (
    <Box sx={{ "&:hover": { button: { display: "flex" } } }} className="day-grid-item-footer">
      {!mode && <em style={{ marginTop: 5, ...(!isCalculated && { color: "blue" }) }}>{totalAmount}</em>}
      {mode &&
        <TextField required variant="standard" type="number" value={amount}
          inputProps={{ maxLength: 10, step: "0.01" }}
          InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
          onChange={(e) => setAmount(e.target.value)}></TextField>
        // <NumberInput required value={amount}
        //   startAdornment={<InputAdornment position="start">£</InputAdornment>}
        //   onChange={(_, v) => setAmount(v)} />
      }
      <IconButton sx={{
        display: "none",
        ...(mode && {
          display: "flex"
        }),
        marginLeft: "auto", marginRight: 0
      }} aria-label="add" size="small" onClick={handleClick} >
        {!mode && <AddBoxOutlinedIcon />}
        {mode && <DoneIcon />}
      </IconButton>
    </Box>
  );
}
