import { useEffect, useState } from 'react';
import Calendar, { DayObject } from './Calendar/Calendar';
import { CalendarDayHeader } from "./Calendar/CalendarDayHeader";
import { CalendarDayBody } from './Calendar/CalendarDayBody';
import { CalendarDayTotal } from './Calendar/CalendarDayTotal';
import dayjs from 'dayjs';
import * as React from 'react';
import PredictionModal, { ModalAction } from './PredictionModal';
import { Alert, Snackbar } from '@mui/material';
import { Roles } from '../authentication/Roles';
import { PlannerServices } from './PlannerServices';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import { useAuth } from '../authentication/useAuth';
import { User } from '../authentication/User';

export type YearAndMonth = [Year: number, Month: number];

export interface PlanDay {
  day?: dayjs.Dayjs,
  total?: number,
  predictions?: Prediction[]
}

export enum RepeatInterval {
  Day = "Day(s)",
  Week = "Week(s)",
  Month = "Month(s)",
  Year = "Year(s)"
}

export interface Prediction {
  id: string,
  associatedId: string,
  name: string,
  incoming: boolean,
  amount: number,
  schedule?: {
    enabled: boolean, interval: RepeatInterval, step: number, until: dayjs.Dayjs, deferred: dayjs.Dayjs[]
  },
  parentId: string
  created: { by: User, on: dayjs.Dayjs },
  modified: { by: User, on: dayjs.Dayjs }
}

export interface PlanDayDictionary {
  [day: string]: PlanDay
}

export interface PredictionResponse {
  prediction: Prediction,
  days: PlanDay[]
}

export interface PlanState {
  id: string,
  planDays: PlanDayDictionary
}

export default function Planner() {

  const today = dayjs();
  const [displayDate, setDisplayDate] = useState([today.year(), today.month() + 1]);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showPredictionModal, setShowPredictionModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState<DayObject>(null);
  const [selectedPrediction, setSelectedPrediction] = useState<Prediction>(null);

  const { user } = useAuth();
  const [plan, setPlan] = useState<PlanState>({ id: "", planDays: {} });
  const [planVm, setPlanVm] = useState<PlanState>({ id: "", planDays: {} });

  function renderPlanningTable() {

    const setYearAndMonth = (d: [number, number]) => setDisplayDate(d);

    function handleAddPlanModal(day: DayObject) {
      setSelectedDay(day);
      setSelectedPrediction(null); // Always create a new prediction here
      setShowPredictionModal(true);
    }

    function handlePredictionModal(day: DayObject, prediction: Prediction) {
      setSelectedDay(day);
      setSelectedPrediction(prediction);
      setShowPredictionModal(true);
    }

    async function handlePredictionDialogClose(
      props: {
        day: DayObject,
        selectedDay: DayObject,
        originalPrediction: Prediction,
        prediction?: Prediction,
        action: ModalAction
      }) {
      if (props.prediction) {
        // const planDay = plan.planDays[props.day.dateString] ?? { day: dayjs(props.selectedDay.dateString), total: 0, predictions: [] };
        if (props.action === ModalAction.Delete) {
          // Deleting a linked prediction from the schedule (can also be moved) adds a record into the Deferred table
          // so the prediction won't be created again
          const response = await PlannerServices.deletePrediction(plan.id, props.day, props.prediction);
          //planDay.predictions = planDay.predictions?.filter(x => x.id !== props.prediction.id) ?? [];
          mergeUpdate(response);
        } else if (props.action === ModalAction.OK) {
          if (!props.prediction.id) {
            const response = await PlannerServices.createPrediction(plan.id, props.selectedDay, props.prediction);
            mergeUpdate(response);
          } else if (!props.prediction.parentId) {
            const response = await PlannerServices.updatePrediction(plan.id, props.day, props.prediction, props.selectedDay);
            // No calculations to do, just reinsert the returned days
            mergeUpdate(response);
          } else if (props.prediction.parentId) {
            // We're updating a linked prediction
            const response = await PlannerServices.updatePrediction(plan.id, props.day, props.prediction, props.selectedDay);
            // No calculations to do, just reinsert the returned days
            mergeUpdate(response);
          }
        }
        setPlan({ ...plan });
      }
      setShowPredictionModal(false);
    }

    function mergeUpdate(dayDictionary: PlanDayDictionary) {
      Object.entries(dayDictionary)
        .map(([key, day]) => {
          const d = { ...day, day: dayjs(key) }
          plan.planDays = { ...plan.planDays, [key]: d };
          return null;
        });
    }

    async function handleTotalUpdate(day: DayObject, amount?: number) {
      setSelectedDay(day);
      const planDay = plan.planDays[day.dateString] ?? { day: dayjs(day.dateString), total: 0, predictions: [] };
      planDay.total = amount;
      plan.planDays = { ...plan.planDays, [day.dateString]: planDay };
      await PlannerServices.updateDayTotal(plan.id, day, amount);
      setPlan({ ...plan });
    }

    function handleSnapshot() {
      localStorage.setItem("snapshot", JSON.stringify({
        date: dayjs(),
        ...plan
      }));
      setNotificationMessage("Snapshot created");
      setOpen(true);
    }

    async function handleRestore() {
      const snapshotRaw = localStorage.getItem("snapshot");
      if (snapshotRaw) {
        const snapshot = JSON.parse(snapshotRaw);
        setNotificationMessage(`Restoring snapshot from '${snapshot.date}'...`);
        await PlannerServices.restorePlan(snapshot);
        setPlan(snapshot as PlanState);
      }
      else {
        setNotificationMessage("Snapshot created");
      }

      setOpen(true);
    }

    function calculateTotal(day: DayObject): [number, boolean] {

      const planDay = planVm.planDays[day.dateString];
      if (planDay?.total > 0 || (planDay?.predictions?.length ?? 0) === 0) {
        return [planDay?.total ?? 0, false];
      }

      const dayOfMonth = dayjs(day.dateString);
      const allPlans = Object.entries(planVm.planDays)
        .map(([d, plans]) => ({ ...plans, day: dayjs(d) }))
        .sort((x, y) => x.day > y.day ? 1 : -1);

      const val = allPlans
        .filter(p => p.day <= dayOfMonth)
        .reduce((p, v) => {
          var t = ((v?.total ?? null) !== null) && v.total !== 0
            ? v.total
            : (p + (v.predictions?.map(x => (x.incoming ? x.amount : x.amount * -1)).reduce((x, y) => x + y, 0) ?? 0));
          return t;
        }, 0);

      return [val, true];
    }

    return (
      <div className="App">
        <Calendar
          yearAndMonth={displayDate}
          onYearAndMonthChange={setYearAndMonth}
          renderDay={(day: DayObject) => (
            <div>
              <CalendarDayHeader day={day} click={handleAddPlanModal} />
              <CalendarDayBody day={day} predictions={planVm.planDays[day.dateString]?.predictions} click={handlePredictionModal} />
              <CalendarDayTotal day={day} total={calculateTotal(day)} click={handleTotalUpdate} />
            </div>
          )}
          allowSnapshot={isFounder}
          onSnapshot={handleSnapshot}
          onRestore={handleRestore}
        />

        {showPredictionModal &&
          <PredictionModal
            show={true}
            day={selectedDay}
            editPrediction={selectedPrediction}
            handleClose={async (props) => await handlePredictionDialogClose(props)} />}
      </div>
    );
  }

  const isFounder = React.useMemo(() => {
    return user?.roles?.indexOf(Roles.Founder) > -1;
  }, [user]);

  interface ScheduledPrediction extends Prediction {
    day: dayjs.Dayjs;
    dateString: string;
  }

  const buildSchedules = React.useCallback((): PlanState => {
    // Flatten the dictionary to a list and clone the entire state to include scheduled (virtual) items
    const state = { ...plan, planDays: { ...plan.planDays } };
    const days = Object.entries(state.planDays)
      .map(([d, day]) => ({ ...day, day: dayjs(d), predictions: [...day.predictions] }))
      .sort((x, y) => x.day > y.day ? 1 : -1);

    const [year, month] = displayDate;
    let asScheduled: ScheduledPrediction[] = [];
    days.forEach(planDay => {
      let [dateWindowStart, dateWindowEnd] = [planDay.day, dayjs(`${year}-${month}-01`).add(1, "month").endOf("month")];
      const scheduledPredictions = planDay.predictions?.filter(p => p.schedule?.enabled).flatMap(p => {
        // Create a new set of predictions to map to a selection of days
        // For example, if this prediction is set to 2023/12/15 repeat once per day
        // generate predictions up to the end of the view date (current month)
        // daily and add them to the associated day plans below
        const scheduled: ScheduledPrediction[] = [];
        let addInterval = (day: dayjs.Dayjs, it: number) => day.add(p.schedule.step, "day");
        if (p.schedule.interval === RepeatInterval.Month) {
          addInterval = (day: dayjs.Dayjs, it: number) => dateWindowStart.add(p.schedule.step * it, "month");
        }
        dateWindowEnd = p.schedule.until ?? dateWindowEnd;
        let stepIteration = 1;
        let cur = addInterval(dateWindowStart, 1);
        while (cur <= dateWindowEnd) {
          const dateString = cur.format("YYYY-MM-DD");
          if (!p.schedule.deferred.find(def => def.isSame(cur))) {
            const nextDay = { ...p, id: `scheduled-${dateString}-${p.id}`, day: cur, dateString, parentId: p.id, schedule: null } as ScheduledPrediction;
            scheduled.push(nextDay);
          }
          cur = addInterval(cur, ++stepIteration);
        }
        return scheduled;
      });
      asScheduled = asScheduled.concat(scheduledPredictions);
    });

    // Convert the scheduled predictions into days/predictions and push them into the state
    asScheduled.forEach(p => {
      const planDay = state.planDays[p.dateString] ?? { day: p.day, total: 0, predictions: [] };
      planDay.predictions = [...(planDay.predictions ?? []), p]?.sort((a, b) => a?.name > b?.name ? 1 : -1);
      state.planDays = { ...state.planDays, [p.dateString]: planDay };
    });

    return state;
  }, [plan, displayDate]);

  useEffect(() => {
    PlannerServices.getPlans().then(
      (p) => {
        setLoading(false);
        setPlan(p);
      }
    );
  }, [displayDate]);

  useEffect(() => {
    setPlanVm(buildSchedules());
  }, [plan, buildSchedules]);

  const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false);
  };

  if (!user?.roles.includes("Standard")) {
    return (
      <>
        Unauthorized
      </>
    )
  }

  let contents = loading
    ? <p><em>Loading...</em></p>
    : renderPlanningTable();

  return (
    <div style={{ flex: 'auto' }}>
      <div>
        <h1 id="tableLabel">Planner</h1>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          {contents}
        </LocalizationProvider>
      </div>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseSnack}
          message={notificationMessage}>
          <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
            {notificationMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};