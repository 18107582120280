import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import "../../custom.css";
import { Box, Drawer, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { List } from "reactstrap";
import styled from "@emotion/styled";
import { useAuth } from "../../authentication/useAuth";

export default function AdministrationShell() {

    const drawerWidth = 200;
    
    const ActiveNavLink = styled(NavLink)({
        "&.active": {
            backgroundColor: "lightgray",
        }
    });

    var { user } = useAuth();
    if (!["Founder", "Admin"].some(r => user?.roles?.includes(r))) { 
        return (
            <Box className="App" sx={{width: '100%', 
                display: 'flex', 
                flexFlow: 'column', 
                flex: '1', 
                marginBottom: '8px'
            }}>
                Unauthorized
            </Box>
        )
    }
    
    return (
        <Box className="App" sx={{width: '100%', 
            display: 'flex', 
            flexFlow: 'column', 
            flex: '1', 
            marginBottom: '8px'
        }}>
            <h1 id="tableLabel">Administration</h1>
            <Box sx={{ 
                display: 'flex',
                flex: '1'
            }}>
                <Drawer
                    open={true}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            position: "relative",
                            borderTop: (theme) => '1px solid ' + theme.palette.divider,
                            borderRadius: '4px'
                        },
                        '& ul': { paddingLeft: '0em' },
                        '& li': { padding: '0 0 0 0' },
                        '& a': { paddingLeft: '4px' }
                    }}
                    variant="permanent"
                    anchor="left">
                    <List>
                        <ListItem>
                            <ListItemButton component={ActiveNavLink} to="/administration" end>
                                <ListItemText>Administration Home</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton component={ActiveNavLink} to="/administration/users">
                                <ListItemText>Users</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton component={Link} to="/messages">
                                <ListItemText>Messages</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Drawer>
                <Box sx={{
                    paddingLeft: '8px', 
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1',
                    minWidth: '0'
                }}>
                    <Outlet key="adminOutlet" />
                </Box>
            </Box>
        </Box>
    );
}