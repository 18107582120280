import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../authentication/useAuth';

export default function ProtectedRoute() {
  const location = useLocation();
  const { user } = useAuth();

  if (user === undefined) {
    return null; // or loading indicator/spinner/etc
  }

  return user
    ? <Outlet />
    : <Navigate to="/login" replace state={{ from: location }} />;
}