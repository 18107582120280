import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Select, Stack, TextField } from '@mui/material';
import { Button } from 'reactstrap';
import { DayObject } from './Calendar/Calendar';
import { Prediction, RepeatInterval } from './Planner';
import { useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm } from "react-hook-form";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useAuth } from '../authentication/useAuth';

export enum ModalAction {
  OK,
  Cancel,
  Delete
}

export default function PredictionModal({ show, day, editPrediction, handleClose }: {
  show: boolean,
  day: DayObject,
  editPrediction?: Prediction,
  handleClose: (props: {
    day: DayObject,
    selectedDay: DayObject,
    originalPrediction: Prediction,
    prediction?: Prediction,
    action: ModalAction
  }) => void
}) {

  const { user } = useAuth();
  const now = dayjs();

  const [prediction, setPrediction] = useState<Prediction>(
    editPrediction ?? {
      id: "",
      associatedId: "",
      name: "",
      amount: 0.00,
      incoming: true,
      schedule: { enabled: false, interval: RepeatInterval.Month, step: 1, until: null, deferred: [] },
      parentId: null,
      created: { by: user, on: now },
      modified: { by: user, on: now }
    });

  if (!prediction.schedule) {
    prediction.schedule = { enabled: false, interval: RepeatInterval.Month, step: 1, until: null, deferred: [] };
  }

  const [amount, setAmount] = useState<string | null>(editPrediction?.amount?.toFixed(2) ?? "");
  const [selectedDay, setSelectedDay] = useState(dayjs(day.dateString));

  const { register, handleSubmit } = useForm();
  const onSubmit = (data: { action: ModalAction }) => {
    prediction.amount = (amount?.length > 0 ? Number.parseFloat(amount) : 0) ?? 0;
    handleClose({
      day,
      selectedDay: {
        dateString: selectedDay.format("YYYY-MM-DD"), dayOfMonth: -1, isCurrentMonth: null
      },
      originalPrediction: editPrediction,
      prediction,
      action: data.action ?? ModalAction.OK
    });
  }

  const editedText = useMemo(() => { 
    if (prediction && prediction.modified && prediction.modified.on) {
      const modifiedOn = prediction.modified.on.format("lll");
      return `Edited by ${prediction.modified.by.name} on ${modifiedOn}`
    } else if (prediction.created && prediction.created.on) { 
      const createdOn = prediction.created.on.format("lll");
      return `Created by ${prediction.created.by.name} on ${createdOn}`
    }
  }, [prediction]);
  
  return (
    <Dialog
      open={show}
      fullWidth={false}
      maxWidth={"xs"}
      onClose={() => handleClose(null)}
    >
      <DialogTitle>
        Prediction Editor {prediction.parentId ? "(Linked)" : ""}
        <Typography component={"h6"} variant="caption" sx={{ fontStyle: "italic" }}>
          {editedText}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup sx={{ mt: 2 }}>
            <Stack spacing={2}>
              <TextField {...register("name")} required id="outlined-required" label="Name" value={prediction.name}
                onChange={(e) => setPrediction({ ...prediction, name: e.target.value })} />
              <Stack spacing={2} direction="row">
                <TextField {...register("amount")} required id="outlined-required" label="Amount" type="number" value={amount}
                  inputProps={{ maxLength: 10, step: "0.01" }}
                  InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
                  onChange={(e) => setAmount(e.target.value)}></TextField>
                <DatePicker
                  label="Date"
                  value={selectedDay}
                  onChange={(newValue) => setSelectedDay(newValue)}
                />
              </Stack>
              <RadioGroup
                name="radio-buttons-group"
                value={prediction.incoming}
                onChange={(e) => {
                  setPrediction({ ...prediction, incoming: e.target.value === 'true' });
                }}>
                <FormControlLabel value={true} control={<Radio />} label="Incoming" />
                <FormControlLabel value={false} control={<Radio />} label="Outgoing" />
              </RadioGroup>
              <FormControl sx={{ mt: 2 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Repeat</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox checked={prediction.schedule?.enabled ?? false} onChange={(e) => {
                      setPrediction({ ...prediction, schedule: { ...prediction.schedule, enabled: e.target.checked } });
                    }} />
                  } label="Schedule repetition"
                />
                <Grid container spacing={1}>
                  <Grid item xs={2} style={{ display: "inline-flex", alignItems: "center" }}>
                    <span>Every</span>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField sx={{ width: "5em" }} disabled={!prediction.schedule?.enabled} value={prediction.schedule?.step} type="number" onChange={(e) => {
                      setPrediction({ ...prediction, schedule: { ...prediction.schedule, step: Number(e.target.value) } });
                    }} />
                  </Grid>
                  <Grid item xs={4} style={{ display: "inline-flex", alignItems: "center" }}>
                    <Select disabled={!prediction.schedule?.enabled} value={prediction.schedule?.interval} onChange={(e) => {
                      setPrediction({ ...prediction, schedule: { ...prediction.schedule, interval: e.target.value as RepeatInterval } });
                    }}>
                      {Object.values(RepeatInterval).map((k, i) => (
                        <MenuItem key={i} value={k}>{k}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={2} style={{ display: "inline-flex", alignItems: "center" }}>
                    <span>Until</span>
                  </Grid>
                  <Grid item xs={8}>
                    <DatePicker
                      disabled={!prediction.schedule?.enabled}
                      label=""
                      value={prediction.schedule?.until}
                      onChange={(newValue) => setPrediction({ ...prediction, schedule: { ...prediction.schedule, until: newValue } })}
                    />
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </FormControl>
            </Stack>
            <Stack>
            </Stack>
          </FormGroup>
          <DialogActions style={{ paddingRight: 0, marginBottom: -10, marginTop: 10 }}>
            {editPrediction !== null && <IconButton sx={{ marginLeft: "-10px", marginRight: "auto" }} aria-label="delete"
              onClick={() => onSubmit({ action: ModalAction.Delete })}>
              <DeleteIcon />
            </IconButton>}
            <Button variant="outlined" color="secondary" onClick={() => onSubmit({ action: ModalAction.Cancel })}>Cancel</Button>
            <Button variant="contained" color="primary" type="submit">OK</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}