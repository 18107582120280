import React from "react";
import "../../custom.css";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

export default function Administration() {

    const versionNumber = process.env.REACT_APP_VERSION ?? "unspecified";
    const environment = process.env.NODE_ENV ?? "unspecified";

    return (
        <>
            Admin Home

            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Property</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Application Version</TableCell>
                        <TableCell>{versionNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Environment</TableCell>
                        <TableCell>{environment}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}