import classNames from "classnames";
import {
  daysOfWeek,
  createDaysForCurrentMonth,
  createDaysForNextMonth,
  createDaysForPreviousMonth,
  isWeekendDay,
  getMonthDropdownOptions,
  getYearDropdownOptions
} from "./helpers";
import { ReactElement } from "react";
import dayjs from "dayjs";
import React from "react";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Button, Fab, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import "./styles.css";

export interface DayObject {
  dateString: string;
  dayOfMonth: number;
  isCurrentMonth: boolean;
}

export default function Calendar({
  yearAndMonth = [2023, 10],
  onYearAndMonthChange = (date: [number, number]) => { },
  renderDay = (day: DayObject): ReactElement | null => null,
  allowSnapshot = false,
  onSnapshot = (): void => { },
  onRestore = (): void => { }
}): React.JSX.Element {

  const [year, month] = yearAndMonth;
  let currentMonthDays = createDaysForCurrentMonth(year, month);
  let previousMonthDays = createDaysForPreviousMonth(
    year,
    month,
    currentMonthDays
  );
  let nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays);
  let calendarGridDayObjects = [
    ...previousMonthDays,
    ...currentMonthDays,
    ...nextMonthDays
  ];

  const handleMonthNavBackButtonClick = () => {
    let nextYear = year;
    let nextMonth = month - 1;
    if (nextMonth === 0) {
      nextMonth = 12;
      nextYear = year - 1;
    }
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleTodayButtonClick = () => {
    let year = dayjs().year();
    let month = dayjs().month();
    onYearAndMonthChange([year, month + 1]);
  };

  const handleMonthNavForwardButtonClick = () => {
    let nextYear = year;
    let nextMonth = month + 1;
    if (nextMonth === 13) {
      nextMonth = 1;
      nextYear = year + 1;
    }
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleMonthSelect = (evt: SelectChangeEvent) => {
    let nextYear = year;
    let nextMonth = parseInt(evt.target.value, 10);
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleYearSelect = (evt: SelectChangeEvent) => {
    let nextMonth = month;
    let nextYear = parseInt(evt.target.value, 10);
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  return (
    <div className="calendar-root">
      <div className="navigation-header">
        <div className="month-nav-arrow-buttons">
          <Button variant="contained" onClick={handleTodayButtonClick}> today </Button>
          <Fab color="primary" size="small" onClick={handleMonthNavBackButtonClick}><ArrowCircleLeftIcon /></Fab>
          <Fab color="primary" size="small" onClick={handleMonthNavForwardButtonClick}><ArrowCircleRightIcon /></Fab>
        </div>
        <Select
          className="month-select"
          size="small"
          value={month as unknown}
          onChange={handleMonthSelect}
        >
          {getMonthDropdownOptions().map((p: { label: string, value: number }) => (
            <MenuItem value={p.value} key={p.value}>
              {p.label}
            </MenuItem>
          ))}
        </Select>
        <Select
          className="year-select"
          size="small"
          value={year as unknown}
          onChange={handleYearSelect}
        >
          {getYearDropdownOptions(year).map((p: { label: string; value: number }): React.JSX.Element => (
            <MenuItem value={p.value} key={p.value}>
              {p.label}
            </MenuItem>
          ))}
        </Select>
        {allowSnapshot && <div style={{ marginLeft: "auto" }} className="month-nav-arrow-buttons">
          <Button variant="text" onClick={onSnapshot}>Snapshot</Button>
          <Button variant="text" onClick={onRestore}>Restore</Button>
        </div>}
      </div>
      <div className="days-of-week">
        {daysOfWeek.map((day, index) => (
          <div
            key={day}
            className={classNames("day-of-week-header-cell", {
              "weekend-day": [6, 0].includes(index)
            })}>
            {day}
          </div>
        ))}
      </div>
      <div className="days-grid">
        {calendarGridDayObjects.map((day) => (
          <div
            key={day.dateString}
            className={classNames("day-grid-item-container", {
              "weekend-day": isWeekendDay(day.dateString),
              "current-month": day.isCurrentMonth
            })}
          >
            <div className="day-content-wrapper">{renderDay(day)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}


