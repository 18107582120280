import { Dialog, DialogTitle, DialogContent, DialogContentText, Box, IconButton, TextField, InputAdornment, DialogActions, Popover, Button, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import RefreshIcon from '@mui/icons-material/Refresh';
import { generateSillyPassword } from 'silly-password-generator';

type DialogResult = {
    hasCancelled: boolean;
    isSuccess: boolean;
};

export default function UserPasswordModal({ show, email, handlePasswordChange, handleClose }: {
    show: boolean,
    email: string,
    handlePasswordChange: (password: string) => Promise<void>,
    handleClose: (result: DialogResult) => void
}) {

    const [generatePasswordCount, setGeneratePasswordCount] = useState(0);
    const [passwords, setPasswords] = useState({ password1: "", password2: "" });
    const generatedPassword = useMemo(
        () => {
            if (show) {
                const pwd = generateSillyPassword({ wordCount: 3 }).replace(/\s/g, '-');
                setPasswords({ password1: pwd, password2: pwd });
                return pwd;
            }
            return passwords.password1;
        }, [generatePasswordCount, show]);

    const [showPassword, setShowPassword] = useState(false);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const copyPopoverOpen = Boolean(anchorEl);

    const hasPasswordError = useMemo(
        () => passwords.password1 !== passwords.password2, [passwords]);
    const getPasswordError = useMemo(
        () => ((passwords.password1 !== passwords.password2) && passwords.password2.length > 0
            ? "Passwords do not match!"
            : ""),
        [passwords]
    );

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Dialog
            fullWidth={true}
            open={show}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    if (hasPasswordError) {
                        return;
                    }
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData as any).entries());
                    handlePasswordChange(formJson.password2)
                        .then(() => {
                            setPasswords({ password1: generatedPassword, password2: generatedPassword });
                            handleClose({ isSuccess: true, hasCancelled: false });
                        }, r => {
                            handleClose({ isSuccess: false, hasCancelled: false });
                        });
                },
            }}>
            <DialogTitle>Password Reset</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Set the new password below, or use the one provided.
                </DialogContentText>
                <Box id="test" sx={{ display: "flex" }}>
                    <pre id="copyText" style={{ display: "flex", flex: "1", flexWrap: "wrap", justifyContent: "center" }}>
                        <span style={{ padding: "8px" }}>{email}</span>
                        <div style={{ width: "100%" }}></div>
                        <span style={{ padding: "8px" }}>{generatedPassword}</span>
                        <IconButton edge="end" color="primary" onClick={(event) => {
                            navigator.clipboard.writeText(email + '\r\n' + generatedPassword);
                            setAnchorEl(event.currentTarget);
                        }}>
                            <CopyAllIcon />
                        </IconButton>

                        <Popover
                            open={copyPopoverOpen}
                            anchorEl={anchorEl}
                            onClose={() => { setAnchorEl(null); }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: -35,
                            }}>
                            <Typography sx={{ p: 2 }}>Text copied!</Typography>
                        </Popover>

                        <IconButton edge="end" color="primary" onClick={() => {
                            setGeneratePasswordCount(generatePasswordCount + 1);
                        }}>
                            <RefreshIcon />
                        </IconButton>
                    </pre>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <TextField
                        style={{ flex: "1" }}
                        autoFocus
                        required
                        margin="dense"
                        id="password"
                        name="password1"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        value={passwords.password1}
                        onChange={(event) => setPasswords({ ...passwords, password1: event.target.value })}
                        variant="standard"
                        sx={{ marginRight: "8px" }} />
                    <TextField
                        style={{ flex: "1" }
                        }
                        required
                        margin="dense"
                        id="password2"
                        name="password2"
                        label="Password Verification"
                        type={showPassword ? 'text' : 'password'}
                        value={passwords.password2}
                        onChange={(event) => {
                            setPasswords({ ...passwords, password2: event.target.value });
                        }}
                        variant="standard"
                        error={hasPasswordError}
                        helperText={getPasswordError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose({ isSuccess: false, hasCancelled: true })}>Cancel</Button>
                <Button variant="contained" type="submit">Change</Button>
            </DialogActions>
        </Dialog>
    )
}