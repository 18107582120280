import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { User } from "./User";

export type FormValues = {
  email: string
  password: string,
  isPersistent: boolean
}

const USER_KEY = "user";

export const AuthContext = createContext({
  user: null as User,
  setUser: (user: User) => { },
  login: (form: FormValues) => { return Promise.resolve(); },
  impersonate: (id: string) => { return Promise.resolve(); },
  logout: () => { },
});

export const useAuth = () => useContext(AuthContext);

export const Auth = ({ children }: { children: ReactNode }) => {

  const auth = useAuth();
  const [user, setUser] = useState<User>(() => {
    return JSON.parse(localStorage.getItem("user"));
  });

  const storeUser = (user: User) => {
    setUser(user);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  };

  useEffect(() => {
    const checkUser = async () => {
      await fetch("/api/user/current", {
        credentials: 'include'
      })
        .then(async (result) => {
          const currentUser = await result?.json();
          storeUser(currentUser);
        })
        .catch(() => storeUser(null));
    }

    checkUser()
  }, [auth]);

  const value = {
    user: user,

    setUser: setUser,

    login: async (form: FormValues) => {
      const data = new FormData()
      data.append("email", form.email);
      data.append("password", form.password);
      data.append("isPersistent", form.isPersistent.toString());

      const response = await fetch("/api/user/signin", {
        method: "POST",
        body: data,
        credentials: "include"
      });

      return await value.storeUserFromResponse(response);
    },

    impersonate: async (id: string) => {
      const response = await fetch(`/api/user/${id}/impersonate`, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        credentials: "include"
      });

      return await value.storeUserFromResponse(response);
    },

    storeUserFromResponse: async (response: Response) => {
      try {
        const result = await response?.json();
        if (response.ok) {
          storeUser(result);
          return Promise.resolve();
        }
        return Promise.reject(result);
      }
      catch {
        storeUser(null);
      }
      return Promise.reject();
    },

    logout: async () => {
      await fetch("/api/user/logout", {
        method: "POST"
      });
      storeUser(null);
    }
  }
  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>
};