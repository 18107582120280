import classNames from "classnames";
import { isCurrentDay } from "./helpers";
import React from "react";
import { DayObject } from "./Calendar";
import { IconButton } from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

export function CalendarDayHeader({ day, click }: {
  day: DayObject;
  click: (day: DayObject) => void;
}): React.JSX.Element {
  return (
    <>
      <div className={classNames("day-grid-item-header", {"current-day": isCurrentDay(day.dateString) })}>
        <span>{day.dayOfMonth}</span>
        <IconButton aria-label="add" size="small" onClick={() => click(day)} >
          <AddBoxOutlinedIcon />
        </IconButton>
      </div>
    </>
  );
}
